<template>
    <div class="locale-changer">
        <a v-for="(lang, i) in $locales"
                :href="`${lang.base}${path}`"
                :key="`Lang${i}`"
                class="locale-changer__button">
            <img :src="require(`@/assets/images/${lang.locale}.png`)" :alt="lang.title">
            <span>{{ lang.title }}</span>
        </a>
    </div>
</template>

<script>
export default {
  name: 'LocaleChanger',
  mounted() {
    this.path = this.locale.base
      ? this.currentRoutePath.substring(this.locale.base.length)
      : this.currentRoutePath;
  },
  computed: {
    $locales() {
      return this.$store.getters.GET_LOCALES;
    },
    locale() {
      return this.$store.getters.GET_LOCALE;
    },
    currentRoutePath() {
      return this.$route.path;
    },
  },
  data() {
    return {
      path: '/',
    };
  },
};
</script>
